var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "34",
      "height": "25",
      "viewBox": "0 0 34 25",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M7.75691 0L13.6172 17.1835H13.6811L19.2431 0H27V24.9788H21.8429V7.28547H21.779L15.6417 25H11.3796L5.22099 7.4554H5.15706V24.9788H0V0H7.75691Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M34 20V25H29V20H34Z",
      "fill": "#00FFC2"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }