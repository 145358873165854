var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticStyle: {
      "background-color": "currentColor"
    },
    attrs: {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M8.14269 18.1422C8.14269 18.2937 8.08249 18.439 7.97533 18.5462C7.86818 18.6534 7.72284 18.7136 7.57129 18.7136H5.8571C5.70555 18.7136 5.56022 18.6534 5.45306 18.5462C5.3459 18.439 5.2857 18.2937 5.2857 18.1422V10.1426C5.2857 9.99104 5.3459 9.8457 5.45306 9.73855C5.56022 9.63139 5.70555 9.57119 5.8571 9.57119H7.57129C7.72284 9.57119 7.86818 9.63139 7.97533 9.73855C8.08249 9.8457 8.14269 9.99104 8.14269 10.1426V18.1422ZM6.7142 8.42839C6.37516 8.42839 6.04374 8.32786 5.76184 8.1395C5.47994 7.95114 5.26023 7.68342 5.13049 7.37019C5.00074 7.05696 4.9668 6.71229 5.03294 6.37977C5.09908 6.04725 5.26234 5.74181 5.50208 5.50208C5.74181 5.26234 6.04725 5.09908 6.37977 5.03294C6.71229 4.9668 7.05696 5.00074 7.37019 5.13049C7.68342 5.26023 7.95114 5.47994 8.1395 5.76184C8.32786 6.04374 8.42839 6.37516 8.42839 6.7142C8.42839 7.16883 8.24779 7.60484 7.92632 7.92632C7.60484 8.24779 7.16883 8.42839 6.7142 8.42839ZM18.9993 18.1422C18.9993 18.2937 18.9391 18.439 18.8319 18.5462C18.7247 18.6534 18.5794 18.7136 18.4279 18.7136H16.7137C16.5621 18.7136 16.4168 18.6534 16.3096 18.5462C16.2025 18.439 16.1423 18.2937 16.1423 18.1422V13.8567C16.1423 13.402 15.9617 12.966 15.6402 12.6446C15.3187 12.3231 14.8827 12.1425 14.4281 12.1425C13.9734 12.1425 13.5374 12.3231 13.216 12.6446C12.8945 12.966 12.7139 13.402 12.7139 13.8567V18.1422C12.7139 18.2937 12.6537 18.439 12.5465 18.5462C12.4394 18.6534 12.294 18.7136 12.1425 18.7136H10.4283C10.2767 18.7136 10.1314 18.6534 10.0242 18.5462C9.91709 18.439 9.85689 18.2937 9.85689 18.1422V10.1426C9.85689 9.99104 9.91709 9.8457 10.0242 9.73855C10.1314 9.63139 10.2767 9.57119 10.4283 9.57119H12.1425C12.294 9.57119 12.4394 9.63139 12.5465 9.73855C12.6537 9.8457 12.7139 9.99104 12.7139 10.1426V10.8283C13.055 10.3091 13.5308 9.89247 14.0904 9.62275C14.6499 9.35303 15.2723 9.24041 15.8909 9.29692C16.7612 9.40423 17.5609 9.83056 18.135 10.4934C18.7092 11.1563 19.0172 12.0085 18.9993 12.8853V18.1422Z",
      "fill": "white"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }