var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-avatar', _vm._b({
    attrs: {
      "color": "deep-purple"
    }
  }, 'v-avatar', _vm.$attrs, false), [_vm.src ? _c('v-img', {
    attrs: {
      "src": _vm.src,
      "alt": _vm.name,
      "eager": ""
    }
  }) : _c('span', {
    staticClass: "text-uppercase white--text",
    class: {
      'font-weight-medium': _vm.$attrs.size <= 40
    },
    style: _vm.$attrs.size ? `font-size: ${Math.round(_vm.$attrs.size / 2.5)}px` : undefined
  }, [_vm._v(_vm._s(_vm.initials))])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }