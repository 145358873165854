import { render, staticRenderFns } from "./SkillPicker.vue?vue&type=template&id=f227269c&scoped=true&"
import script from "./SkillPicker.vue?vue&type=script&lang=ts&"
export * from "./SkillPicker.vue?vue&type=script&lang=ts&"
import style0 from "./SkillPicker.vue?vue&type=style&index=0&id=f227269c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f227269c",
  null
  
)

export default component.exports