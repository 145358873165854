import { v4 as uuidv4 } from 'uuid';
import LocationEntity from '@/entities/LocationEntity';
import { EventEntity } from '@/entities/EventEntity';
import BaseApiService from './BaseApiService';
import LocationQueryEntity from '@/entities/LocationQueryEntity';
import EventsFilterEntity from '@/entities/EventsFilterEntity';
import GetEventResponseEntity from '@/entities/GetEventResponseEntity';

class EventService extends BaseApiService {
  public get(): Promise<EventEntity[]> {
    return this.http.get<EventEntity[]>('/events').then(x => x.data);
  }

  public getEvents(filter: EventsFilterEntity): Promise<GetEventResponseEntity> {
    return this.http.post('/event/search', { ...filter }).then(x => x.data);
  }

  public getEvent(eventId: string): Promise<EventEntity> {
    return this.http.get(`/event/getEvent?id=${eventId}`).then(x => x.data);
  }

  public joinEvent(eventId: string): Promise<EventEntity> {
    const RqUid = uuidv4();

    return this.http.post(`/event/joinEvent?RqUid=${RqUid}`, { id: eventId }).then(x => x.data);
  }

  public getJoinedUsers(eventId: string): Promise<EventEntity> {
    const RqUid = uuidv4();

    return this.http.get(`/event/getJoinedUsers?RqUid=${RqUid}&id=${eventId}`).then(x => x.data);
  }

  public getById(id: string): Promise<EventEntity> {
    const RqUid = uuidv4();

    return this.http.get(`/event/getevent?RqUid=${RqUid}&id=${id}`).then(x => x.data);
  }

  // eslint-disable-next-line
  public uploadImage(file: any): Promise<string> {
    const formData = new FormData();
    formData.append('image', file);

    // eslint-disable-next-line
    return this.http.post<any>(`/profile/uploadImage?RqUid=${uuidv4()}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(x => x.data.url);
  }

  public update(event: EventEntity): Promise<number | string> {
    const body = {
      id: event.id,
      time: event.time,
      timeEnd: event.timeEnd,
      title: event.title,
      description: event.title,
      image: event.image || undefined,
      location: event.location || undefined,
      category: event.category,
      link: event.link || undefined,
    };

    return this.http.post('/event/editevent', body).then(x => x.data.id);
  }

  public create(event: EventEntity): Promise<number | string> {
    const body = {
      time: event.time,
      timeEnd: event.timeEnd,
      title: event.title,
      description: event.description,
      image: event.image || undefined,
      placeId: event.placeId,
      location: event.location || undefined,
      category: event.category,
      link: event.link || undefined,
    };

    return this.http.post('/event/addevent', body).then(x => x.data.id);
  }

  public delete(id: string): Promise<void> {
    return this.http.post(`/event/delEvent?RqUid=${uuidv4()}`, id).then(x => x.data);
  }

  public unjoinEvent(eventId: string): Promise<EventEntity> {
    return this.http.post(`/event/unjoinEvent?RqUid=${uuidv4()}`, { id: eventId }).then(x => x.data);
  }

  public postLocations(
    { userInput, sessionToken }: LocationQueryEntity,
  ): Promise<LocationEntity[]> {
    return this.http.post('/location', {
      userInput, sessionToken, RqUid: uuidv4(),
    }).then(x => x.data.location);
  }

  public getLocations(query: string): Promise<LocationEntity[]> {
    return this.http
      .get(`/database/getLocations?RqUid=${uuidv4()}&q=${query}`)
      .then(x => x.data.items);
  }

  public getPeerboardAuth(): Promise<string> {
    return this.http.get(`/peerboard/auth?RqUid=${uuidv4()}`).then(x => x.data.token);
  }

  public resolvePlaceId(placeId: string): Promise<string> {
    return this.http.post('/resolvePlaceId', { placeId }).then(x => x.data.placeId);
  }
}

export default new EventService();
