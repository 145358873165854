var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticStyle: {
      "background-color": "currentColor"
    },
    attrs: {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M9.86111 20V12.9616H8V10.4274H9.86111V8.26287C9.86111 6.56198 10.9605 5 13.4936 5C14.5193 5 15.2777 5.09832 15.2777 5.09832L15.2179 7.46481C15.2179 7.46481 14.4445 7.45728 13.6004 7.45728C12.6869 7.45728 12.5406 7.87826 12.5406 8.57697V10.4274H15.2906L15.1709 12.9616H12.5406V20H9.86111Z",
      "fill": "white"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }