import SkillsEntity from '@/entities/SkillsEntity';
import { v4 as uuidv4 } from 'uuid';
import BaseApiService from './BaseApiService';

class SkillsService extends BaseApiService {
  public findByQuery(query: string): Promise<SkillsEntity> {
    const RqUid = uuidv4();

    return this.http.get(`/database/getSkills?RqUid=${RqUid}&q=${query}&count=20`).then(x => x.data);
  }
}

export default new SkillsService();
