var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "layout-width pa-4 pa-sm-6"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "align-self-start hidden-sm-and-down",
    style: _vm.$vuetify.breakpoint.lgAndUp ? 'flex-basis: 20%; max-width: 20%' : undefined,
    attrs: {
      "cols": "0",
      "md": "3"
    }
  }, [_c('v-card', {
    staticClass: "flex-grow-1"
  }, [_c('TheMainMenu')], 1), _vm.$route.meta.showUserFilters ? _c('TheFilters', {
    staticClass: "mt-6"
  }) : _vm._e()], 1), _c('v-col', {
    staticClass: "d-flex flex-column",
    style: _vm.$vuetify.breakpoint.lgAndUp ? 'flex-basis: 80%; max-width: 80%' : undefined,
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-snackbar', {
    attrs: {
      "color": "error",
      "app": "",
      "top": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function () {
        return [_c('v-icon', {
          on: {
            "click": function ($event) {
              _vm.snackbar = false;
            }
          }
        }, [_vm._v("mdi-close")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.snackbar,
      callback: function ($$v) {
        _vm.snackbar = $$v;
      },
      expression: "snackbar"
    }
  }, [_c('span', [_vm._v("Oops, что-то пошло не так. Попробуйте позже.")])]), _c('v-alert', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.profileErrorsAlert && _vm.$route.name !== 'ProfileEdit',
      expression: "profileErrorsAlert && $route.name !== 'ProfileEdit'"
    }],
    attrs: {
      "type": "error",
      "prominent": ""
    }
  }, [_c('div', {
    staticClass: "d-sm-flex align-center"
  }, [_c('span', {
    staticClass: "mr-4"
  }, [_vm._v("Неправильно заполнен профиль. Пожалуйста, проверьте правильность заполнения вашего профиля.")]), _c('MButton', {
    staticClass: "d-block ml-auto mt-4 mt-sm-0",
    attrs: {
      "outlined": "",
      "same-color-on-hover": ""
    },
    on: {
      "click": function ($event) {
        _vm.$analytics.sendEvent(_vm.AmplitudeEvent.check_profile);
        _vm.$router.push({
          name: 'ProfileEdit'
        });
      }
    }
  }, [_vm._v("Проверить")])], 1)]), _vm._t("default"), _c('div', {
    staticClass: "align-self-center d-flex align-center fill-height mt-10"
  }, [_c('v-progress-circular', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "color": "deep-purple",
      "indeterminate": ""
    }
  })], 1), _c('v-bottom-sheet', {
    model: {
      value: _vm.bottomSheet,
      callback: function ($$v) {
        _vm.bottomSheet = $$v;
      },
      expression: "bottomSheet"
    }
  }, [_c('TheFilters', {
    staticClass: "px-4"
  }), _c('v-sheet', {
    staticClass: "d-flex justify-center pt-1 pb-3"
  }, [_c('MButton', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.bottomSheet = false;
      }
    }
  }, [_vm._v("Показать")])], 1)], 1)], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }