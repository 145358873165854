var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "viewBox": "0 0 512 512",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M479.7,101.7l-67.9,320.3c-5.1,22.6-18.5,28.2-37.5,17.6l-103.5-76.2l-49.9,48c-5.5,5.5-10.1,10.1-20.8,10.1l7.4-105.4l191.8-173.3c8.3-7.4-1.8-11.6-13-4.1L149.3,287.9L47.3,256c-22.2-6.9-22.6-22.2,4.6-32.8L451.1,69.3C469.6,62.4,485.7,73.4,479.7,101.7z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }