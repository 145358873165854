import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import Analytics from '@/analytics';

import VueYandexMetrika from 'vue-yandex-metrika-ts';

import App from './App.vue';
import router from './router';
import store from './store';

import Component from 'vue-class-component';
// Register the router hooks with their names (https://class-component.vuejs.org/guide/additional-hooks.html)
Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate']);

Vue.use(Analytics);
Vue.use(VueYandexMetrika, {
  id: 84869629,
  router,
  env: process.env.NODE_ENV,
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
