import store from '@/store';
import { NavigationGuardNext } from 'vue-router';
import TokenHelper from '@/helpers/TokenHelper';
import AuthenticationService from '@/services/AuthenticationService';

async function getAuthUser(next: NavigationGuardNext) {
  await store.dispatch('user/getSelf');

  next();
}

export default async function authentication(next: NavigationGuardNext) {
  if (TokenHelper.getToken('accessToken')) {
    await getAuthUser(next);
  } else if (TokenHelper.getToken('refreshToken')) {
    try {
      const { accessToken, refreshToken } = await AuthenticationService.postRefreshToken(
        TokenHelper.getToken('refreshToken'),
      );
      TokenHelper.setToken('accessToken', accessToken);
      TokenHelper.setToken('refreshToken', refreshToken);

      await getAuthUser(next);
    } catch (e) {
      next({ name: 'Auth' });
    }
  } else {
    next({ name: 'Auth' });
  }
}
