
import { Component, Vue, Watch } from 'vue-property-decorator';
import LocationPicker from '@/components/LocationPicker.vue';
import LocationEntity from '@/entities/LocationEntity';
import SkillPicker from '@/components/SkillPicker.vue';

@Component({ components: { SkillPicker, LocationPicker } })
export default class TheFilters extends Vue {
  private location = {} as LocationEntity;
  private locationPickerKey = Date.now();
  private skills: string[] = [];

  private search(filter: 'location' | 'skills') {
    switch (filter) {
      case 'location':
        if (this.location?.description) this.$analytics.changeLocationUsersFilter(this.location.description);
        break;
      case 'skills':
        this.$analytics.changeSkillsUsersFilter(this.skills);
        break;
      // no default
    }

    this.$router.push({
      name: 'Search',
      query: {
        ...this.$route.query,
        location: this.location?.description,
        placeId: this.location?.placeId,
        skills: this.skills?.length ? this.skills : undefined,
      },
    });
  }

  // TODO (AZ): Replace search to placeId. Now backend doesn't resolve location by placeId.
  @Watch('$route.query', { deep: true })
  private onRouteQueryChange(value: { [key: string]: string | string[] }) {
    if (this.location?.description !== value.location) {
      this.location = (value.location ? { description: value.location, placeId: value.placeId } : {}) as LocationEntity;
      this.locationPickerKey = Date.now(); // Re-render LocationPicker to add an initial value to items on created hook
    }

    if (JSON.stringify(this.skills) !== JSON.stringify(value.skills)) {
      this.skills = value.skills
        ? Array.isArray(value.skills) ? [...value.skills] : [value.skills]
        : [];
    }
  }

  created() {
    this.onRouteQueryChange(this.$route.query as { [key: string]: string | string[] });
  }
}
