
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import UserService from '@/services/UserService';
import LocationEntity from '@/entities/LocationEntity';

@Component
export default class LocationAutocomplete extends Vue {
  @Prop(Array) readonly rules?: ((value: LocationEntity) => boolean | string)[];

  // noinspection JSMismatchedCollectionQueryUpdate
  private items: LocationEntity[] = [];
  private text = '';
  private query = '';
  private loading = false;

  @Watch('text')
  private async onTextChange(value: string) {
    if (!value) {
      this.items = [];
      this.$emit('input', {});

      return;
    }

    // if no request in the process && value is different from initial
    if (!this.loading && value !== (this.$attrs.value as LocationEntity)?.description) {
      this.loading = true;
      this.query = value;
      this.items = await UserService.postLocations(value);
      this.loading = false;
    }
  }

  @Watch('loading')
  private async onLoadingChange(value: boolean) {
    // if user wrote new text while we were waiting response from the server -> sent request with new text
    if (!value && this.query !== this.text) await this.onTextChange(this.text);
  }

  created() {
    const value = this.$attrs.value as LocationEntity;
    // items should have initial item to display it
    if (value?.placeId) this.items = [value];
  }
}
