var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-combobox', _vm._g(_vm._b({
    staticClass: "row-gap vuetify-bug-workaround",
    attrs: {
      "items": _vm.items,
      "search-input": _vm.text,
      "loading": _vm.loading,
      "append-icon": "",
      "multiple": "",
      "small-chips": "",
      "deletable-chips": "",
      "hide-selected": "",
      "hide-details": "",
      "outlined": "",
      "dense": "",
      "background-color": "light-grey"
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.text = $event;
      },
      "update:search-input": function ($event) {
        _vm.text = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          item = _ref.item,
          parent = _ref.parent,
          selected = _ref.selected;
        return [_c('MTag', _vm._b({
          attrs: {
            "input-value": selected,
            "outlined": "",
            "small": "",
            "color": "grey-purple",
            "text-color": "#686868"
          }
        }, 'MTag', attrs, false), [_c('span', {
          staticClass: "mx-1"
        }, [_vm._v(_vm._s(item))]), _c('v-icon', {
          staticStyle: {
            "margin-top": "2px"
          },
          attrs: {
            "x-small": "",
            "color": "deep-purple"
          },
          on: {
            "click": function ($event) {
              return parent.selectItem(item);
            }
          }
        }, [_vm._v("$close")])], 1)];
      }
    }])
  }, 'v-combobox', _vm.$attrs, false), _vm.$listeners));

}
var staticRenderFns = []

export { render, staticRenderFns }