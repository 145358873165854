
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MAvatar extends Vue {
  @Prop(String) readonly src?: string;

  @Prop({
    type: String,
    required: true,
    validator: (value: string) => /\p{L}/u.test(value), // Name should contain at least one letter
  }) readonly name!: string;

  get initials() {
    return this.name.split(' ').map(word => word[0]).slice(0, 2).join(''); // Get the first two initials
  }
}
