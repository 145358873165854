var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', _vm._g(_vm._b({
    directives: [{
      name: "ripple",
      rawName: "v-ripple",
      value: false,
      expression: "false"
    }],
    class: _vm.$attrs.hasOwnProperty('same-color-on-hover') ? undefined : 'highlight-on-hover',
    style: _vm.$attrs.hasOwnProperty('dark') ? 'transition: 0.3s' : undefined,
    attrs: {
      "color": _vm.$attrs.hasOwnProperty('dark') ? 'deep-purple' : _vm.$attrs.color,
      "elevation": "0"
    }
  }, 'v-btn', _vm.$attrs, false), _vm.$listeners), [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }