
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { cutUserName } from '@/helpers/StringHelper';
import MAvatar from '@/components/MAvatar.vue';
import UserEntity from '@/entities/UserEntity';
import MainMenuItemEntity from '@/entities/MainMenuItemEntity';

const userModule = namespace('user');

@Component({ components: { MAvatar } })
export default class TheProfileMenu extends Vue {
  @userModule.State readonly user!: UserEntity;

  // noinspection JSMismatchedCollectionQueryUpdate
  private items: MainMenuItemEntity[] = [];
  private showMenu = false;

  get cutUserName() {
    return cutUserName(this.user.fullName);
  }

  created() {
    this.items = [{
      icon: 'mdi-account',
      title: 'Мой профиль',
      to: { name: 'Profile', params: { id: this.user.id } },
    }, {
      icon: 'mdi-pencil',
      title: 'Редактировать профиль',
      to: { name: 'ProfileEdit' },
    }, {
      icon: 'mdi-logout',
      title: 'Выйти',
      to: { name: 'Logout' },
    }];
  }
}
