import axios, { AxiosInstance } from 'axios';
import TokenHelper from '@/helpers/TokenHelper';
import { v4 as uuidv4 } from 'uuid';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

const { VUE_APP_BASE_URI: apiUrl } = process.env;

class BaseApiService {
  public http: AxiosInstance;

  constructor() {
    this.http = axios.create({
      baseURL: `${apiUrl}/v1`,
    });

    this.http.interceptors.request.use(config => {
      if (!config.headers) config.headers = {};

      const token = TokenHelper.getToken('accessToken') || undefined;

      if (token) {
        config.headers.Authorization = token && `Bearer ${token}`;
      }

      config.headers['X-Request-Id'] = uuidv4();

      return config;
    });

    // eslint-disable-next-line
    const refreshAuthLogic = (failedRequest: any) => this.http.post('/auth/refresh/', {
      RqUid: uuidv4(),
      refreshToken: TokenHelper.getToken('refreshToken'),
    })
      .then(tokenRefreshResponse => {
        const { accessToken, refreshToken } = tokenRefreshResponse.data;
        TokenHelper.setToken('accessToken', accessToken);
        TokenHelper.setToken('refreshToken', refreshToken);

        failedRequest.response.config.headers.Authorization = `Bearer ${accessToken}`;

        return Promise.resolve();
      })
      .catch(e => {
        TokenHelper.removeToken('refreshToken');
        window.location.href = '/auth/';

        return Promise.reject(e);
      });

    createAuthRefreshInterceptor(this.http, refreshAuthLogic, {
      pauseInstanceWhileRefreshing: true, // default: false
    });
  }
}

export default BaseApiService;
