var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-autocomplete', _vm._g(_vm._b({
    attrs: {
      "search-input": _vm.text,
      "items": _vm.items,
      "loading": _vm.loading,
      "rules": _vm.rules,
      "item-text": "description",
      "item-value": "placeId",
      "prepend-inner-icon": "mdi-map-marker",
      "append-icon": "",
      "return-object": "",
      "hide-no-data": "",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "background-color": "light-grey"
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.text = $event;
      },
      "update:search-input": function ($event) {
        _vm.text = $event;
      }
    }
  }, 'v-autocomplete', _vm.$attrs, false), _vm.$listeners));

}
var staticRenderFns = []

export { render, staticRenderFns }