import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import authentication from '@/middleware/authentication';
import CookieHelper from '@/helpers/CookieHelper';
import { AmplitudeEvent } from '@/analytics/Analytics';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'Search' },
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import(/* webpackChunkName: "community" */ '@/views/VMembers.vue'),
    props: route => ({
      filter: {
        ...route.query,
        skills: route.query.skills
          ? (Array.isArray(route.query.skills) ? [...route.query.skills] : [route.query.skills])
          : undefined,
      },
    }),
    meta: { newLayout: true, showUserFilters: true },
  },
  {
    path: '/navigator',
    name: 'Navigator',
    component: () => import(/* webpackChunkName: "navigator" */ '@/views/VNavigator.vue'),
    meta: { newLayout: true },
  },
  {
    path: '/help/',
    name: 'Help',
    component: () => import(/* webpackChunkName: "help" */ '@/views/VHelp.vue'),
    meta: { newLayout: true },
    children: [
      {
        path: '*',
        component: () => import(/* webpackChunkName: "all" */ '@/views/VHelp.vue'),
        meta: { newLayout: true },
      },
    ],
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import(/* webpackChunkName: "products" */ '@/views/VProducts.vue'),
    meta: { newLayout: true },
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import(/* webpackChunkName: "projects" */ '@/views/VProjects.vue'),
    meta: { newLayout: true },
  },
  {
    path: '/favorites/',
    name: 'Favorites',
    component: () => import(/* webpackChunkName: "favorites" */ '@/views/VMembers.vue'),
    props: { title: 'Мои контакты', filter: { isFriend: true }, noItemsMessage: 'У вас нет сохраненных контактов' },
    meta: { newLayout: true },
  },
  {
    path: '/profile',
    name: 'ProfileEdit',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/VProfileEdit.vue'),
    meta: { newLayout: true },
  },
  {
    path: '/profile/:id',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/VProfile.vue'),
    props: true,
    meta: { newLayout: true },
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter: (to, from, next) => {
      CookieHelper.removeCookie('accessToken');
      CookieHelper.removeCookie('refreshToken');

      Vue.prototype.$analytics.sendEvent(AmplitudeEvent.click_to_exit);
      Vue.prototype.$analytics.changeUser();

      next({ name: 'Auth' });
    },
  },
  {
    path: '/auth',
    redirect: { name: 'Auth' },
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Auth.vue'),
    children: [
      {
        path: '',
        name: 'Auth',
        component: () => import(/* webpackChunkName: "login" */ '@/views/auth/VLogin.vue'),
      },
      {
        path: 'magic-link',
        component: () => import(/* webpackChunkName: "magic-link" */ '@/views/auth/VMagicLink.vue'),
      },
    ],
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "not-found" */ '@/views/VNotFound.vue'),
    meta: { newLayout: true },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta?.newLayout) await authentication(next);
  else next();
});

export default router;
