import Vue from 'vue';
import Vuetify from 'vuetify/lib';

// Icons
import FacebookIcon from '@/components/icons/square/FacebookIcon.vue';
import InstagramIcon from '@/components/icons/square/InstagramIcon.vue';
import LinkedInIcon from '@/components/icons/square/LinkedInIcon.vue';
import TelegramIcon from '@/components/icons/square/TelegramIcon.vue';
import TwitterIcon from '@/components/icons/square/TwitterIcon.vue';
import VkIcon from '@/components/icons/square/VkIcon.vue';
import ChatsIcon from '@/components/icons/ChatsIcon.vue';

// Translation provided by Vuetify (typescript)
import ru from 'vuetify/src/locale/ru';
import en from 'vuetify/src/locale/en';

Vue.use(Vuetify);

// noinspection NonAsciiCharacters
export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
      variations: false,
    },
    themes: {
      light: {
        primary: '#4A0A52', // Deep Purple
        'deep-purple': '#4A0A52',
        'light-purple': '#B85CF0',
        'grey-purple': '#B6AFBC',
        'light-grey': '#F1F1F1',
        lime: '#00FFC2',
        'primary-text': '#16061C', // Black
        'secondary-text': '#727272', // Grey
        anchor: '#16061C', // Black
        error: '#FF5F72',
      },
    },
  },
  icons: {
    values: {
      facebook: { component: FacebookIcon },
      instagram: { component: InstagramIcon },
      linkedin: { component: LinkedInIcon },
      telegram: { component: TelegramIcon },
      twitter: { component: TwitterIcon },
      вконтакте: { component: VkIcon },
      chats: { component: ChatsIcon },
    },
  },
  lang: {
    locales: { ru, en },
    current: process.env.VUE_APP_I18N_LOCALE || 'ru',
  },
});
