var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple",
      value: false,
      expression: "false"
    }],
    staticClass: "font-weight-bold",
    attrs: {
      "href": "mailto:hello@mesto.co",
      "color": "deep-purple",
      "plain": "",
      "block": "",
      "x-small": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$analytics.sendEvent(_vm.AmplitudeEvent.bug_report);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-alert-circle-outline")]), _vm._v(" Сообщить о проблеме ")], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }