var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "dark": "",
      "left": "",
      "offset-y": "",
      "open-on-hover": "",
      "open-on-focus": "",
      "transition": "slide-y-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('div', _vm._g({
          staticClass: "d-none d-md-flex align-center justify-end overflow-hidden cursor-pointer",
          staticStyle: {
            "height": "56px"
          },
          on: {
            "click": function ($event) {
              _vm.showMenu = !_vm.showMenu;
            },
            "keydown": function ($event) {
              _vm.showMenu = !_vm.showMenu;
            }
          }
        }, on), [_c('span', {
          staticClass: "lime-dot-after text-subtitle-1 white--text text-no-wrap mr-4"
        }, [_vm._v(_vm._s(_vm.cutUserName))]), _c('MAvatar', {
          attrs: {
            "name": _vm.user.fullName,
            "src": _vm.user.imagePath,
            "size": "40"
          }
        })], 1)];
      }
    }]),
    model: {
      value: _vm.showMenu,
      callback: function ($$v) {
        _vm.showMenu = $$v;
      },
      expression: "showMenu"
    }
  }, [_c('v-list', {
    attrs: {
      "color": "deep-purple"
    }
  }, _vm._l(_vm.items, function (item, i) {
    return _c('v-list-item', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple",
        value: false,
        expression: "false"
      }],
      key: i,
      staticClass: "highlight-on-hover",
      attrs: {
        "to": item.to,
        "dense": ""
      }
    }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v(_vm._s(item.icon))])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.title))])], 1)], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }