import Vue from 'vue';
import Amplitude from '@/analytics/amplitude';
import { Analytics } from '@/analytics/Analytics';

Vue.use(Amplitude);

export default {
  // eslint-disable-next-line
  install: (vue: any) => {
    vue.prototype.$analytics = new Analytics(vue.prototype.$amplitude);
  },
};
