var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-chip', _vm._g(_vm._b({
    directives: [{
      name: "ripple",
      rawName: "v-ripple",
      value: false,
      expression: "false"
    }],
    staticClass: "no-box-shadow-on-click truncate-text font-weight-medium",
    attrs: {
      "x-small": "",
      "label": ""
    }
  }, 'v-chip', _vm.$attrs, false), _vm.$listeners), [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }