import { v4 as uuidv4 } from 'uuid';
import AuthEntity from '@/entities/AuthEntity';
import AuthMagicLinkEntity from '@/entities/AuthMagicLinkEntity';
import { AxiosResponse } from 'axios';
import BaseApiService from './BaseApiService';
import { CustomAxiosRequestConfig } from 'axios-auth-refresh/dist/utils';

class AuthenticationService extends BaseApiService {
  private customAxiosRequestConfig: CustomAxiosRequestConfig = { skipAuthRefresh: true };

  public postAuthMagicLink(email: string): Promise<AuthMagicLinkEntity> {
    return this.http.post<AuthMagicLinkEntity>('/auth/magicLink', { email, RqUid: uuidv4() })
      .then(x => x.data);
  }

  public postSendMagicLink(email: string, tokenId: string): Promise<void> {
    return this.http.post<void>('/email/sendMagicLink', { email, tokenId, RqUid: uuidv4() })
      .then(x => x.data);
  }

  public postRefreshToken(refreshToken: string): Promise<AuthEntity> {
    return this.http.post<AuthEntity, AxiosResponse<AuthEntity>>('/auth/refresh', {
      refreshToken,
      RqUid: uuidv4(),
    }, this.customAxiosRequestConfig)
      .then((x: AxiosResponse<AuthEntity>) => x.data);
  }

  public postRefreshTokenByPassword(email: string, password: string): Promise<AuthEntity> {
    return this.http.post<AuthEntity, AxiosResponse<AuthEntity>>(
      '/auth/getRefreshTokenByPassword',
      { RqUid: uuidv4(), email, password },
      this.customAxiosRequestConfig,
    ).then((x: AxiosResponse<AuthEntity>) => x.data);
  }
}

export default new AuthenticationService();
