import { AmplitudeClient } from 'amplitude-js';

const isDebug = process.env.VUE_APP_DEBUG === 'true';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isEmpty(value: any) {
  // Value is empty if null or undefined or empty string '' or 0
  return !value
    // has length and it's zero
    || (Object.prototype.hasOwnProperty.call(value, 'length') && value.length === 0)

    // is an object and has no keys
    || (typeof value === 'object' && Object.keys(value).length === 0);
}

// noinspection JSUnusedGlobalSymbols
export enum AmplitudeEvent {
  /* eslint-disable camelcase */

  // Search events
  find_someone = 'find_someone',
  filter_1_changed = 'filter_1_changed',
  filter_2_changed = 'filter_2_changed',
  filter_3_changed = 'filter_3_changed',
  count_users_in_searching = 'count_users_in_searching',

  // Profile events
  show_someone = 'show_someone',
  show_my_profile = 'show_my_profile',
  add_to_favorite = 'add_to_favorite',
  write_in_social = 'write_in_social',

  // Profile edit events
  edit_my_profile = 'edit_my_profile',
  save_my_profile = 'save_my_profile',

  // Menu events (are sent from TheMainMenuItem.vue component directly)
  menu_members = 'menu_members',
  menu_experts = 'menu_experts',
  menu_products = 'menu_products',
  menu_projects = 'menu_projects',
  menu_guide = 'menu_guide',
  menu_chats = 'menu_chats',
  menu_shop = 'menu_shop',
  menu_my_contacts = 'menu_my_contacts',
  bug_report = 'bug_report',

  // Product events (are sent from ProductCard.vue component directly)
  products_experts = 'products_experts',
  products_mastermind = 'products_mastermind',
  products_accelerator = 'products_accelerator',
  products_mesto_power = 'products_mesto_power',
  products_radio = 'products_radio',
  products_library = 'products_library',
  products_studio = 'products_studio',
  products_university = 'products_university',

  // Login events
  view_login_screen = 'view_login_screen',
  repeat_view_login_screen = 'repeat_view_login_screen',
  login_success = 'login_success', // user logged in via magic-link
  login_by_password = 'login_by_password', // user logged in via password
  sign_up = 'sign_up',
  click_to_exit = 'click_to_exit',

  // Other
  back_to_main = 'back_to_main',
  check_profile = 'check_profile',

  /* eslint-enable camelcase */
}

export class Analytics {
  constructor(private amplitude: AmplitudeClient) {}

  findSomeone = (query?: string) => this.sendEvent(AmplitudeEvent.find_someone, { search_string: query });

  // TODO (AZ): Implement event when 'can help' tag is fixed in DB
  changeIsFreeUsersFilter = (busy: boolean) => this.sendEvent(AmplitudeEvent.filter_1_changed,
    { tag: busy ? 'Пока занят' : 'Готов помогать' });

  changeLocationUsersFilter = (location?: string) => this.sendEvent(AmplitudeEvent.filter_2_changed,
    { input: location });

  changeSkillsUsersFilter = (skills?: string[]) => this.sendEvent(AmplitudeEvent.filter_3_changed, { input: skills });

  showSomeone = (userId: string) => this.sendEvent(AmplitudeEvent.show_someone, { user_id_2: userId });

  addToFavorite = (userId: string) => this.sendEvent(AmplitudeEvent.add_to_favorite, { user_id_2: userId });

  writeInSocial = (userId: string, socialNetwork: string) => this.sendEvent(AmplitudeEvent.write_in_social,
    { user_id_2: userId, social_network: socialNetwork });

  countUsersInSearching = (count: number) => this.sendEvent(AmplitudeEvent.count_users_in_searching, { count });

  changeUser = (userId?: string) => this.amplitude.setUserId(userId || null);

  sendEvent(event: AmplitudeEvent, params?: object) {
    // Send event if no params passed || params object has non empty value (0 is allowed).
    // In other words don't send event when params are defined and have only empty values.
    if (!params || Object.values(params).some(value => !isEmpty(value) || value === 0)) {
      if (isDebug) console.log('event', event, params);
      // eslint-disable-next-line no-restricted-globals
      else if (location.host === 'app.mesto.co') this.amplitude.logEvent(event, params);
    }
  }
}
