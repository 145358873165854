var render = function render(){
  var _vm$item$beta;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list-item', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple",
      value: false,
      expression: "false"
    }],
    staticClass: "highlight-on-hover",
    attrs: {
      "to": _vm.item.to,
      "href": _vm.item.href,
      "target": _vm.item.href ? '_blank' : undefined,
      "rel": _vm.item.href ? 'noopener noreferrer' : undefined,
      "active-class": "light-purple--text"
    },
    on: {
      "click": function ($event) {
        _vm.item.amplitudeEvent ? _vm.$analytics.sendEvent(_vm.item.amplitudeEvent) : undefined;
      }
    }
  }, [_c('v-list-item-icon', {
    staticClass: "align-self-center my-0"
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.item.icon))])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "font-weight-bold text-wrap",
    staticStyle: {
      "display": "list-item"
    }
  }, [_c('v-badge', {
    staticClass: "mt-0",
    style: _vm.item.beta ? 'position: absolute' : undefined,
    attrs: {
      "value": (_vm$item$beta = _vm.item.beta) !== null && _vm$item$beta !== void 0 ? _vm$item$beta : false,
      "content": "beta",
      "color": "deep-purple"
    }
  }, [_vm._v(_vm._s(_vm.item.title))])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }