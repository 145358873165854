
import { Component, Vue } from 'vue-property-decorator';
import MainMenu from './TheMainMenu.json';
import MainMenuItemEntity from '@/entities/MainMenuItemEntity';
import TheMainMenuItem from '@/components/layout/TheMainMenuItem.vue';
import TheMainMenuProblemButton from '@/components/layout/TheMainMenuProblemButton.vue';

@Component({ components: { TheMainMenuProblemButton, TheMainMenuItem } })
export default class TheMainMenuList extends Vue {
  private items = MainMenu.items as MainMenuItemEntity[];
}
