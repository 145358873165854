import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import UserState from '@/entities/states/UserState';
import RootState from '@/entities/states/RootState';
import UserService from '@/services/UserService';
import TokenHelper from '@/helpers/TokenHelper';
import UserEntity from '@/entities/UserEntity';
import { UserContact, UserContactSummaryEntity } from '@/entities/UserContactSummaryEntity';
import UserContactService from '@/services/UserContactService';
import UserContactEntity from '@/entities/UserContactEntity';

export enum userMutation {
  setUser = 'setUser',
  setContacts = 'setContacts'
}

const state: UserState = {
  user: {} as UserEntity,
};

const getters: GetterTree<UserState, RootState> = {
  contacts: localState => {
    const contacts = {} as UserContactSummaryEntity;
    // eslint-disable-next-line no-unused-expressions
    localState.user.contacts?.forEach(
      contact => { contacts[contact.title as keyof typeof UserContact] = contact.url; });

    return contacts;
  },
};

const actions: ActionTree<UserState, RootState> = {
  async getSelf({ commit }) {
    const user = await UserService.getSelfData();
    const { permissions } = TokenHelper.getParsedToken();
    commit(userMutation.setUser, { ...user, permissions });
  },

  async updateUser({ commit }, updatedUser: UserEntity) {
    await UserService.update(updatedUser);
    commit(userMutation.setUser, updatedUser);
  },

  async updateContacts({ commit, getters: localGetters }, contacts: UserContactSummaryEntity) {
    const ids = {} as UserContactSummaryEntity;
    (await UserContactService.getSelfContacts())
      .forEach(contact => { ids[contact.title as keyof typeof UserContact] = contact.id; });

    const promises: Promise<string | void>[] = [];
    Object.entries(contacts).forEach(([stringKey, value]) => {
      const key = stringKey as keyof typeof UserContact;
      if (contacts[key] && !localGetters.contacts[key]) {
        // if contact has a value && initialContacts has no value -> create contact
        promises.push(UserContactService.create({ title: key, url: contacts[key] ?? '' }));
      } else if (contacts[key] && contacts[key] !== localGetters.contacts[key]) {
        // if contact has a value && value is different from initial -> update value
        promises.push(UserContactService.update({ id: ids[key], title: key, url: value ?? '' }));
      } else if (!contacts[key] && localGetters.contacts[key]) {
        // if contact is empty && initial contact not empty -> delete contact
        promises.push(UserContactService.delete(ids[key] ?? ''));
      }
    });

    await Promise.all(promises);

    const updatedContacts = Object.entries(contacts).map(([key, value]) => ({ title: key, url: value }));
    commit(userMutation.setContacts, updatedContacts);
  },
};

const mutations: MutationTree<UserState> = {
  [userMutation.setUser](localState: UserState, user: UserEntity): void {
    localState.user = { ...user };
  },

  [userMutation.setContacts](localState: UserState, contacts: UserContactEntity[]): void {
    localState.user.contacts = [...contacts];
  },
};

const user: Module<UserState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default user;
