<template>
  <v-app>
    <template v-if="$route.meta.newLayout">
      <TheSideMenu/>

      <TheHeaderBar/>

      <v-main>
        <TheLayout>
          <!-- key attribute needs to fully recreate view on route change
               cause 'Участники' and 'Мои контакты' pages use one view component -->
          <router-view :key="$route.fullPath"/>
        </TheLayout>
      </v-main>
    </template>

    <router-view v-else/>
  </v-app>
</template>

<script>
import TheHeaderBar from '@/components/layout/TheHeaderBar.vue';
import TheLayout from '@/components/layout/TheLayout.vue';
import TheSideMenu from '@/components/layout/TheSideMenu.vue';

export default {
  components: { TheSideMenu, TheLayout, TheHeaderBar },
};
</script>

<style>
@import '@/static/styles/fonts.css';
@import '@/static/styles/global.css';
</style>
