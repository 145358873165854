var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-navigation-drawer', {
    staticStyle: {
      "z-index": "11"
    },
    attrs: {
      "app": "",
      "temporary": "",
      "right": ""
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.showSideMenu(false);
      }
    },
    model: {
      value: _vm.drawer,
      callback: function ($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_c('v-list-item', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple",
      value: false,
      expression: "false"
    }],
    staticClass: "pr-0",
    attrs: {
      "to": {
        name: 'Profile',
        params: {
          id: _vm.user.id
        }
      }
    }
  }, [_c('v-list-item-avatar', [_c('MAvatar', {
    attrs: {
      "name": _vm.user.fullName,
      "src": _vm.user.imagePath,
      "size": "40"
    }
  })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "lime-dot-after text-subtitle-1 deep-purple--text"
  }, [_vm._v(_vm._s(_vm.cutUserName))])], 1), _c('v-list-item-action', {
    staticClass: "ma-0 pa-4",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _vm.$route.name === 'ProfileEdit' ? _vm.showSideMenu(false) : _vm.$router.push({
          name: 'ProfileEdit'
        });
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1)], 1), _c('v-divider', {
    staticClass: "grey-purple"
  }), _c('TheMainMenu'), _c('v-footer', {
    staticClass: "px-2",
    attrs: {
      "absolute": ""
    }
  }, [_c('TheMainMenuItem', {
    attrs: {
      "item": {
        icon: 'mdi-logout',
        title: 'Выйти',
        to: {
          name: 'Logout'
        }
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }