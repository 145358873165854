import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import RootState from '@/entities/states/RootState';
import layout from './modules/layout';
import user from './modules/user';
import {
  allEventsSearch,
  event,
} from '@/store/modules';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  modules: {
    layout,
    user,
    allEventsSearch,
    event,
  },
};

export default new Vuex.Store<RootState>(store);
