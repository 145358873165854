
import { Component, Vue, Watch } from 'vue-property-decorator';
import MTag from '@/components/MTag.vue';
import SkillsService from '@/services/SkillsService';

@Component({ components: { MTag } })
export default class SkillPicker extends Vue {
  // noinspection JSMismatchedCollectionQueryUpdate
  private items: string[] = [];
  private text = '';
  private query = '';
  private loading = false;

  @Watch('text')
  private async onTextChange(value: string) {
    if (!value) {
      this.items = [];

      return;
    }

    if (!this.loading) {
      this.loading = true;
      this.query = value;
      ({ items: this.items } = await SkillsService.findByQuery(value));
      this.loading = false;
    }
  }

  @Watch('loading')
  private async onLoadingChange(value: boolean) {
    // if user wrote new text while we were waiting response from the server -> sent request with new text
    if (!value && this.query !== this.text) await this.onTextChange(this.text);
  }
}
