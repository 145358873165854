var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticStyle: {
      "background-color": "currentColor"
    },
    attrs: {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M18.1605 8C18.6561 8 18.7609 8.25526 18.6561 8.60334C18.448 9.56075 16.4478 12.3761 16.4478 12.3761C16.2749 12.6546 16.2045 12.793 16.4478 13.1067C16.5338 13.2266 16.7165 13.4056 16.9256 13.6105C17.1407 13.8213 17.3838 14.0594 17.5781 14.2887C18.2833 15.0814 18.8148 15.7514 18.9622 16.2117C19.097 16.6743 18.8627 16.9086 18.3941 16.9086H16.7532C16.3142 16.9086 16.0927 16.6623 15.6142 16.1301C15.4115 15.9047 15.1627 15.628 14.8317 15.297C13.8578 14.359 13.4401 14.2363 13.1976 14.2363C12.8675 14.2363 12.7679 14.3306 12.7679 14.7992V16.2709C12.7679 16.6751 12.6391 16.9094 11.5964 16.9094C9.85674 16.9094 7.94716 15.8539 6.59077 13.9069C4.55618 11.0556 4 8.89977 4 8.46411C4 8.22157 4.09357 8.00075 4.56292 8.00075H6.20376C6.62296 8.00075 6.7809 8.18415 6.93735 8.63852C7.74056 10.977 9.09396 13.0199 9.65014 13.0199C9.85974 13.0199 9.9548 12.9233 9.9548 12.3941V9.9777C9.9173 9.28878 9.67678 8.99102 9.49871 8.77059C9.38888 8.63461 9.30281 8.52806 9.30281 8.37727C9.30281 8.19313 9.46 8 9.7205 8H12.3C12.6481 8 12.7686 8.18564 12.7686 8.60334V11.8543C12.7686 12.2024 12.9199 12.3237 13.0239 12.3237C13.232 12.3237 13.4064 12.2024 13.7882 11.8184C14.9709 10.4987 15.8056 8.46336 15.8056 8.46336C15.9104 8.22083 16.1012 8 16.5189 8H18.1605Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M18.6561 8.60334C18.7609 8.25526 18.6561 8 18.1605 8H16.5189C16.1012 8 15.9104 8.22083 15.8056 8.46336C15.8056 8.46336 14.9709 10.4987 13.7882 11.8184C13.4064 12.2024 13.232 12.3237 13.0239 12.3237C12.9199 12.3237 12.7686 12.2024 12.7686 11.8543V8.60334C12.7686 8.18564 12.6481 8 12.3 8H9.7205C9.46 8 9.30281 8.19313 9.30281 8.37727C9.30281 8.52806 9.38888 8.63461 9.49871 8.77059C9.67678 8.99102 9.9173 9.28878 9.9548 9.9777V12.3941C9.9548 12.9233 9.85974 13.0199 9.65014 13.0199C9.09396 13.0199 7.74056 10.977 6.93735 8.63852C6.7809 8.18415 6.62296 8.00075 6.20376 8.00075H4.56292C4.09357 8.00075 4 8.22157 4 8.46411C4 8.89977 4.55618 11.0556 6.59077 13.9069C7.94716 15.8539 9.85674 16.9094 11.5964 16.9094C12.6391 16.9094 12.7679 16.6751 12.7679 16.2709V14.7992C12.7679 14.3306 12.8675 14.2363 13.1976 14.2363C13.4401 14.2363 13.8578 14.359 14.8317 15.297C15.1627 15.628 15.4115 15.9047 15.6142 16.1301C16.0927 16.6623 16.3142 16.9086 16.7532 16.9086H18.3941C18.8627 16.9086 19.097 16.6743 18.9622 16.2117C18.8148 15.7514 18.2833 15.0814 17.5781 14.2887C17.3838 14.0594 17.1407 13.8213 16.9256 13.6105C16.7165 13.4056 16.5338 13.2266 16.4478 13.1067C16.2045 12.793 16.2749 12.6546 16.4478 12.3761C16.4478 12.3761 18.448 9.56075 18.6561 8.60334Z",
      "fill": "white"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }