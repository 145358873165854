
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { cutUserName } from '@/helpers/StringHelper';
import TheMainMenu from '@/components/layout/TheMainMenu.vue';
import UserEntity from '@/entities/UserEntity';
import MAvatar from '@/components/MAvatar.vue';
import TheMainMenuItem from '@/components/layout/TheMainMenuItem.vue';

const userModule = namespace('user');
const layoutModule = namespace('layout');

@Component({ components: { TheMainMenuItem, MAvatar, TheMainMenu } })
export default class TheSideMenu extends Vue {
  @userModule.State readonly user!: UserEntity;
  @layoutModule.State readonly sideMenu!: boolean;

  @layoutModule.Mutation showSideMenu!: (show: boolean) => void;

  get drawer(): boolean {
    return this.sideMenu;
  }

  set drawer(value: boolean) {
    this.showSideMenu(value);
  }

  get cutUserName() {
    return cutUserName(this.user.fullName);
  }
}
