import { Module, ActionContext, ActionTree } from 'vuex';
import RootState from '@/entities/states/RootState';
import EventService from '@/services/EventService';
import { EventEntity } from '@/entities/EventEntity';
import EventsState from '@/entities/states/EventsState';
import EventsFilterEntity from '@/entities/EventsFilterEntity';
import moment from 'moment';

const getDefaultState = (): EventsState => ({
  filter: {
    createdByMe: false,
    joinedByMe: false,
    offset: 0,
    count: 20,
    to: moment().add(7, 'days').format(),
    from: moment().format(),
  },
  events: [],
  total: 0,
  isMoreItems: true,
});

const state = getDefaultState;

const actions: ActionTree<EventsState, RootState> = {
  async findEvents(
    { commit, state: currentState }: ActionContext<EventsState, RootState>,
    filter: EventsFilterEntity,
  ) {
    commit('setFilter', filter);

    const { data, total } = await EventService.getEvents(filter);

    const events = [
      ...(filter.offset ? currentState.events : []), ...data, ...getDefaultState().events,
    ];

    commit('setEvents', events);
    commit('setTotal', total);
    if (data.length < currentState.filter.count) {
      commit('setIsMoreItems', false);
    } else {
      commit('setIsMoreItems', true);
    }
  },

  async refreshEvent(
    { commit, state: currentState }: ActionContext<EventsState, RootState>,
    { eventId, userId }: { eventId: string; userId: string },
  ) {
    const item = currentState.events.find(el => el.id === eventId);
    if (item) {
      const data = await EventService.getJoinedUsers(eventId);
      const isJoined = !!data.joined?.find(el => el.id === userId);

      commit('setEvent', { ...item, isJoined, joined: data.joined });
    } else {
      throw new Error('Проблемы с обновлением ивента');
    }
  },
};

const mutations = {
  resetState(currentState: EventsState): void {
    Object.assign(currentState, getDefaultState());
  },

  setFilter(currentState: EventsState, filter: EventsFilterEntity): void {
    currentState.filter = { ...filter };
  },

  setEvents(currentState: EventsState, events: EventEntity[]): void {
    currentState.events = [...events];
  },

  setEvent(currentState: EventsState, event: EventEntity): void {
    const eventIndex: number = currentState.events.findIndex(el => el.id === event.id);
    currentState.events.splice(eventIndex, 1, event);
  },

  setTotal(currentState: EventsState, total: number): void {
    currentState.total = total;
  },

  setIsMoreItems(currentState: EventsState, isMoreItems: boolean): void {
    currentState.isMoreItems = isMoreItems;
  },
};

const namespaced = true;

const events: Module<EventsState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
};

export default events;
