
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import TheMainMenu from '@/components/layout/TheMainMenu.vue';
import TheFilters from '@/components/layout/TheFilters.vue';
import MButton from '@/components/MButton.vue';
import ProfileValidationMixin from '@/mixins/ProfileValidationMixin';
import UserEntity from '@/entities/UserEntity';
import { ValidationRule } from '@/entities/ProfileValidationEntity';
import { UserContactSummaryEntity } from '@/entities/UserContactSummaryEntity';
import CookieHelper from '@/helpers/CookieHelper';
import { AmplitudeEvent } from '@/analytics/Analytics';

const layoutModule = namespace('layout');
const userModule = namespace('user');

@Component({ components: { MButton, TheFilters, TheMainMenu } })
export default class TheLayout extends ProfileValidationMixin {
  @userModule.State readonly user!: UserEntity;
  @layoutModule.State readonly loading!: boolean;
  @layoutModule.State readonly errorSnackbar!: boolean;
  @layoutModule.State readonly profileErrorsAlert!: boolean;
  @layoutModule.State readonly mobileFilters!: boolean;

  @userModule.Getter('contacts') readonly userContacts!: UserContactSummaryEntity;

  @layoutModule.Mutation readonly showError!: (show: boolean) => void;
  @layoutModule.Mutation readonly showProfileErrorsAlert!: (show: boolean) => void;
  @layoutModule.Mutation readonly showMobileFilters!: (show: boolean) => void;

  AmplitudeEvent = AmplitudeEvent;

  get snackbar(): boolean {
    return this.errorSnackbar;
  }

  set snackbar(value: boolean) {
    this.showError(value);
  }

  get bottomSheet(): boolean {
    return this.mobileFilters;
  }

  set bottomSheet(value: boolean) {
    this.showMobileFilters(value);
  }

  // Validate profile on created hook
  created() {
    function isObjectInvalid<T>(obj: T, rules: object) {
      return Object.entries(rules).some(([key, propertyRules]) => propertyRules
        .some((rule: ValidationRule) => typeof (rule(obj[key as keyof T] as unknown as string)) === 'string'));
    }

    if (this.$route.name !== 'ProfileEdit' && CookieHelper.getCookie('profileValidation') !== 'done') {
      // If (user is not on ProfileEdit page && (user profile doesn't follow rules || user contacts don't follow rules
      // || no contacts provided) --> then show error alert
      if (isObjectInvalid<UserEntity>(this.user, this.profileRules)
        || isObjectInvalid<UserContactSummaryEntity>(this.userContacts, this.contactsRules)
        || !this.isContactProvided(this.userContacts)) {
        this.showProfileErrorsAlert(true);
      } else {
        const date = new Date();
        date.setMonth(date.getMonth() + 3);
        const utcSeconds = date.getTime() / 1000;
        CookieHelper.setCookie('profileCheck', 'done', utcSeconds);
      }
    }
  }
}
