import { v4 as uuidv4 } from 'uuid';
import LocationEntity from '@/entities/LocationEntity';
import UserEntity from '@/entities/UserEntity';
import BaseApiService from './BaseApiService';

class UserService extends BaseApiService {
  public getSelfData(): Promise<UserEntity> {
    const RqUid = uuidv4();

    return this.http.get(`/user?RqUid=${RqUid}`).then(x => x.data.user);
  }

  public async setPassword(password: string): Promise<boolean> {
    return (await this.http.post('/user/setPassword', { RqUid: uuidv4(), password })).status === 200;
  }

  public getById(id: string): Promise<UserEntity> {
    const RqUid = uuidv4();

    return this.http.get(`/users/${id}?RqUid=${RqUid}`).then(x => x.data.user);
  }

  public async update(profile: UserEntity): Promise<string> {
    let placeId;
    if (profile.placeId) {
      placeId = await this.resolvePlaceId(profile.placeId);
    }

    const body = {
      id: profile.id,
      fullName: profile.fullName,
      username: profile.username,
      email: profile.email,
      imagePath: profile.imagePath,
      location: profile.location,
      placeId,
      busy: profile.busy,
      about: profile.about,
      RqUid: uuidv4(),
      skills: profile.skills && profile.skills.length ? profile.skills : undefined,
      role: profile.role || undefined,
    };

    return this.http.put<UserEntity>('/user/', body).then(x => x.data.id);
  }

  // eslint-disable-next-line
  public uploadImage(file: any): Promise<string> {
    const formData = new FormData();
    formData.append('image', file);

    // eslint-disable-next-line
    return this.http.post<any>(`/profile/uploadImage?RqUid=${uuidv4()}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(x => x.data.url);
  }

  public get(): Promise<UserEntity[]> {
    return this.http.get<UserEntity[]>('/users').then(x => x.data);
  }

  public postLocations(query: string): Promise<LocationEntity[]> {
    return this.http.post('/location', { userInput: query, sessionToken: uuidv4() }).then(x => x.data.location);
  }

  public getLocations(query: string): Promise<LocationEntity[]> {
    return this.http.get(`/database/getLocations?RqUid=${uuidv4()}&q=${query}`).then(x => x.data.items);
  }

  public getPeerboardAuth(): Promise<string> {
    return this.http.get(`/peerboard/auth?RqUid=${uuidv4()}`).then(x => x.data.token);
  }

  public resolvePlaceId(placeId: string): Promise<string> {
    return this.http.post('/resolvePlaceId', { placeId }).then(x => x.data.placeId);
  }
}

export default new UserService();
