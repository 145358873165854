import { Module, MutationTree } from 'vuex';
import RootState from '@/entities/states/RootState';
import LayoutState from '@/entities/states/LayoutState';

export enum layoutMutation {
  showSideMenu = 'showSideMenu',
  showError = 'showError',
  setLoading = 'setLoading',
  showProfileErrorsAlert = 'showProfileErrorsAlert',
  showMobileFilters = 'showMobileFilters',
}

const state: LayoutState = {
  sideMenu: false,
  loading: false,
  errorSnackbar: false,
  profileErrorsAlert: false,
  mobileFilters: false,
};

const mutations: MutationTree<LayoutState> = {
  [layoutMutation.showSideMenu](localState: LayoutState, value: boolean): void {
    localState.sideMenu = value;
  },

  [layoutMutation.showError](localState: LayoutState, value: boolean): void {
    localState.errorSnackbar = value;
  },

  [layoutMutation.setLoading](localState: LayoutState, value: boolean): void {
    localState.loading = value;
  },

  [layoutMutation.showProfileErrorsAlert](localState: LayoutState, value: boolean): void {
    localState.profileErrorsAlert = value;
  },

  [layoutMutation.showMobileFilters](localState: LayoutState, value: boolean): void {
    localState.mobileFilters = value;
  },
};

const layout: Module<LayoutState, RootState> = {
  namespaced: true,
  state,
  mutations,
};

export default layout;
