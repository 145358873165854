var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticStyle: {
      "background-color": "currentColor"
    },
    attrs: {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M5.65371 10.7078L18.055 6.07479C18.6209 5.82678 19.1679 6.20693 18.9517 7.04652L16.8401 16.6878C16.6925 17.3736 16.2665 17.5362 15.6734 17.2198L12.4566 14.9179L10.9101 16.3748C10.9043 16.3802 10.8986 16.3855 10.8929 16.3909C10.7204 16.5525 10.5774 16.6865 10.2659 16.6865L10.4855 13.5064L16.454 8.28793C16.7156 8.06296 16.3973 7.95251 16.049 8.15783L8.68235 12.6607L5.49844 11.6984C4.81227 11.4945 4.80738 11.0371 5.65371 10.7078Z",
      "fill": "white"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }