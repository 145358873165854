import amplitude from 'amplitude-js';
import jwtDecode from 'jwt-decode';
import TokenHelper from '@/helpers/TokenHelper';

const {
  VUE_APP_AMPLITUDE_API_KEY: apiKey,
} = process.env;

export default {
  // eslint-disable-next-line
  install: (Vue: any) => {
    const token = TokenHelper.getToken('refreshToken');
    const { userId } = token ? jwtDecode<{ userId: string }>(token) : { userId: undefined };

    amplitude.getInstance().init(apiKey, userId);
    Vue.prototype.$amplitude = amplitude;
  },
};
