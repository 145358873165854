import CookieHelper from '@/helpers/CookieHelper';
import jwtDecode from 'jwt-decode';

type TokenType = 'accessToken' | 'refreshToken';

class TokenHelper {
  static getToken = (tokenType: TokenType) => CookieHelper.getCookie(tokenType);

  static setToken = (tokenType: TokenType, jwtToken: string) => {
    const { exp } = jwtDecode<{ exp: number }>(jwtToken);
    CookieHelper.setCookie(tokenType, jwtToken, exp);
  };

  // eslint-disable-next-line
  static parseToken = (token: any) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64)
      .split('')
      .map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`)
      .join(''));

    return JSON.parse(jsonPayload);
  }

  static getParsedToken = () => TokenHelper.parseToken(
    TokenHelper.getToken('accessToken'),
  )

  static removeToken = (tokenType: TokenType) => CookieHelper.removeCookie(tokenType);
}

export default TokenHelper;
