var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list', {
    staticClass: "px-2 py-3"
  }, [_vm._l(_vm.items, function (item) {
    return _c('TheMainMenuItem', {
      key: item.icon,
      attrs: {
        "item": item
      }
    });
  }), _c('v-divider', {
    staticClass: "grey-purple my-3 mx-4"
  }), _c('TheMainMenuProblemButton')], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }