var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_vm.$route.meta.newLayout ? [_c('TheSideMenu'), _c('TheHeaderBar'), _c('v-main', [_c('TheLayout', [_c('router-view', {
    key: _vm.$route.fullPath
  })], 1)], 1)] : _c('router-view')], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }