import { Component, Vue } from 'vue-property-decorator';
import { ContactsValidationEntity, ProfileValidationEntity } from '@/entities/ProfileValidationEntity';
import { UserContact, UserContactSummaryEntity } from '@/entities/UserContactSummaryEntity';

@Component
export default class ProfileValidationMixin extends Vue {
  // noinspection NonAsciiCharacters
  protected placeholders = {
    // facebook: 'https://facebook.com/',
    // instagram: 'https://instagram.com/',
    linkedin: 'https://linkedin.com/in/',
    telegram: 'https://t.me/',
    twitter: 'https://twitter.com/',
    вконтакте: 'https://vk.com/',
  };

  protected profileRules: ProfileValidationEntity = {
    fullName: [
      (value: string) => !!value || 'Это поле обязательно для заполнения',
      (value: string) => (value?.includes(' ')) || 'Укажите Имя и Фамилию',
      (value: string) => (value?.split(' ').every(word => word.length >= 2)) || 'Укажите полное Имя и Фамилию',
      (value: string) => (value?.length <= 100) || 'Очень длинное имя (обратитесь в поддержку для регистрации)',
    ],
    location: [(value: string) => !!value || 'Это поле обязательно для заполнения'],
    about: [
      (value: string) => value?.length >= 100 || 'Минимум 100 символов',
      (value: string) => value?.length <= 2000 || 'Максимум 2000 символов',
    ],
  };

  protected contactsRules: ContactsValidationEntity = {};

  constructor() {
    super();

    // Creating contact rules on placeholder basis
    Object.entries(this.placeholders).forEach(([contact, placeholder]) => {
      this.contactsRules[contact as keyof typeof UserContact] = [
        // User contact is correct if it's empty ||
        // || (it's starts with placeholder and 1+ more symbols after && it doesn't contain spaces)
        (value: string) => !value
          || (
            new RegExp(`^(http[s]?://(www\\.)?|www\\.)${placeholder?.substring(8)}.+`).test(value)
            && !value.includes(' ')
          ) || `Введите ссылку вида: ${placeholder}...`,
      ];
    });
  }

  protected isContactProvided(contacts: UserContactSummaryEntity) {
    return Object.keys(this.placeholders).some(contact => contacts[contact as keyof typeof UserContact]);
  }
}
