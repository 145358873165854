var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', _vm._g(_vm._b({
    attrs: {
      "autocomplete": "off",
      "outlined": "",
      "dense": _vm.$attrs.hasOwnProperty('dense') ? _vm.$attrs.dense : true,
      "background-color": _vm.$attrs.hasOwnProperty('dark') ? 'rgba(255, 255, 255, 0.1)' : 'light-grey'
    }
  }, 'v-text-field', _vm.$attrs, false), _vm.$listeners), [_vm._l(Object.keys(_vm.$slots), function (slot) {
    return _vm._t(slot, null, {
      "slot": slot
    });
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }