var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1 pa-6"
  }, [_c('v-icon', {
    staticClass: "mr-4"
  }, [_vm._v("mdi-tune")]), _vm._v(" Фильтры ")], 1), _c('div', {
    staticClass: "pa-2 pt-0"
  }, [_c('LocationPicker', {
    key: _vm.locationPickerKey,
    attrs: {
      "hide-details": "",
      "placeholder": "Страна, регион, город"
    },
    on: {
      "change": function ($event) {
        return _vm.search('location');
      }
    },
    model: {
      value: _vm.location,
      callback: function ($$v) {
        _vm.location = $$v;
      },
      expression: "location"
    }
  }), _c('SkillPicker', {
    staticClass: "mt-2",
    attrs: {
      "prepend-inner-icon": "mdi-lightning-bolt",
      "placeholder": "Навыки"
    },
    on: {
      "change": function ($event) {
        return _vm.search('skills');
      }
    },
    model: {
      value: _vm.skills,
      callback: function ($$v) {
        _vm.skills = $$v;
      },
      expression: "skills"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }