
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AmplitudeEvent } from '@/analytics/Analytics';
import MLogo from '@/components/icons/MLogo.vue';
import MestoLogo from '@/components/icons/MestoLogo.vue';
import MInput from '@/components/MInput.vue';
import TheProfileMenu from '@/components/layout/TheProfileMenu.vue';

const layoutModule = namespace('layout');

@Component({ components: { TheProfileMenu, MInput, MLogo, MestoLogo } })
export default class TheSearchBar extends Vue {
  @layoutModule.Mutation showSideMenu!: (show: boolean) => void;

  private searchQuery = this.$route.query?.q ?? '';

  AmplitudeEvent = AmplitudeEvent;

  // noinspection JSMethodCanBeStatic
  get isDevelopment() {
    return window.location.hostname === 'dev.mesto.co';
  }

  private search() {
    if (this.searchQuery !== (this.$route.query?.q ?? '')) {
      this.$analytics.findSomeone(this.searchQuery as string);
      this.$router.push({ name: 'Search', query: { q: this.searchQuery ? this.searchQuery : undefined } });
    }
  }

  private clearSearch() {
    if (this.$route.query?.q) this.$router.push({ name: 'Search' });
  }

  @Watch('$route.query.q')
  private onRouteQueryChange(value: string | undefined) {
    // Clean up if route have no search query (/search?q=...)
    this.searchQuery = value ?? '';
  }
}
