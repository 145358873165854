var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticStyle: {
      "background-color": "currentColor"
    },
    attrs: {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M5 9C5 6.79086 6.79086 5 9 5L15 5C17.2091 5 19 6.79086 19 9V15C19 17.2091 17.2091 19 15 19H9C6.79086 19 5 17.2091 5 15L5 9ZM11.9981 15.5917C10.0106 15.5917 8.40651 13.9876 8.40651 12C8.40651 10.0124 10.0106 8.40832 11.9981 8.40832C13.9857 8.40832 15.5897 10.0124 15.5897 12C15.5897 13.9876 13.9857 15.5917 11.9981 15.5917ZM9.66383 12C9.66383 10.7146 10.7128 9.66567 11.9981 9.66567C13.2835 9.66567 14.3324 10.7146 14.3324 12C14.3324 13.2854 13.2835 14.3343 11.9981 14.3343C10.7128 14.3343 9.66383 13.2854 9.66383 12ZM14.898 8.26122C14.898 8.72529 15.2728 9.10004 15.7368 9.10004C15.847 9.10018 15.9562 9.07858 16.058 9.03647C16.1598 8.99437 16.2523 8.93258 16.3303 8.85467C16.4082 8.77675 16.47 8.68422 16.5121 8.58239C16.5542 8.48056 16.5758 8.37142 16.5756 8.26122C16.5756 7.79716 16.2009 7.42241 15.7368 7.42241C15.2728 7.42241 14.898 7.79716 14.898 8.26122Z",
      "fill": "white"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }