import { ActionContext, Module } from 'vuex';
import RootState from '@/entities/states/RootState';
import EventService from '@/services/EventService';
import LocationEntity from '@/entities/LocationEntity';
import LocationQueryEntity from '@/entities/LocationQueryEntity';
import { EventState } from '@/entities/states/EventState';
import { EventEntity } from '@/entities/EventEntity';

const state = (): {
  event: EventEntity;
} => ({
  event: {
    id: '',
    creator: '',
    joined: [],
    time: '',
    timeEnd: '',
    title: '',
    description: '',
    image: '',
    link: '',
    location: '',
    category: '',
  },
});

const actions = {

  getById(
    context: ActionContext<EventState, RootState>,
    id: string,
  ): Promise<EventEntity> {
    return EventService.getById(id);
  },

  async uploadImage(
    context: ActionContext<EventState, RootState>,
    image: HTMLImageElement,
  ): Promise<string> {
    const canvas = document.createElement('canvas');
    const maxSize = 640;
    let { width, height } = image;
    const scale = Math.min(Math.min(maxSize / width, 1.0), maxSize / height);
    width *= scale;
    height *= scale;
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.drawImage(image, 0, 0, width, height);
      const dataUrl = canvas.toDataURL('image/jpeg');

      return EventService.uploadImage(await (await fetch(dataUrl)).blob());
    }

    return EventService.uploadImage(image);
  },

  async postLocations(
    context: ActionContext<EventState, RootState>,
    data: LocationQueryEntity,
  ): Promise<LocationEntity[]> {
    return EventService.postLocations(data);
  },

  async getLocations(
    context: ActionContext<EventState, RootState>,
    query: string,
  ): Promise<LocationEntity[]> {
    return EventService.getLocations(query);
  },

  async getEvent(
    { commit, dispatch }: ActionContext<EventState, RootState>,
    { eventId, userId }: { eventId: string; userId: string },
  ) {
    const event = await EventService.getEvent(eventId);

    commit('setEvent', event);
    await dispatch('setJoined', { eventId, userId });
  },

  async joinEvent(
    { dispatch }: ActionContext<EventState, RootState>,
    { eventId, userId }: { eventId: string; userId: string },
  ) {
    await EventService.joinEvent(eventId);
    await dispatch('setJoined', { eventId, userId });
  },

  async getJoinedUsers(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit }: ActionContext<EventState, RootState>,
    eventId: string,
  ) {
    await EventService.getJoinedUsers(eventId);
  },

  async unjoinEvent(
    { dispatch }: ActionContext<EventState, RootState>,
    { eventId, userId }: { eventId: string; userId: string },
  ) {
    await EventService.unjoinEvent(eventId);
    await dispatch('setJoined', { eventId, userId });
  },

  async setJoined(
    { commit, state: currentState }: ActionContext<EventState, RootState>,
    { eventId, userId }: { eventId: string; userId: string },
  ) {
    const data = await EventService.getJoinedUsers(eventId);
    const isJoined = !!data.joined?.find(el => el.id === userId);

    commit('setEvent', { ...currentState.event, isJoined, joined: data.joined });
  },

  saveEvent(
    context: ActionContext<EventState, RootState>, event: EventEntity,
  ) {
    return EventService.create(event);
  },

  deleteEvent(
    context: ActionContext<EventState, RootState>, eventId: string,
  ) {
    return EventService.delete(eventId);
  },

  editEvent(
    context: ActionContext<EventState, RootState>, event: EventEntity,
  ) {
    return EventService.update(event);
  },
};

const mutations = {
  setEvent(currentState: EventState, event: EventEntity): void {
    currentState.event = { ...event };
  },
};

const namespaced = true;

const event: Module<EventState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
};

export default event;
