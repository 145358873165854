var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app-bar', {
    staticClass: "bg-purple-gradient",
    staticStyle: {
      "z-index": "10"
    },
    attrs: {
      "app": "",
      "short": "",
      "dark": ""
    }
  }, [_c('v-container', {
    staticClass: "layout-width pa-4 pa-sm-6"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "d-flex flex-grow-0",
    attrs: {
      "md": "3"
    }
  }, [_c('router-link', {
    staticClass: "d-flex",
    attrs: {
      "to": {
        name: 'Home'
      }
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.$analytics.sendEvent(_vm.AmplitudeEvent.back_to_main);
      }
    }
  }, [_c('v-badge', {
    attrs: {
      "value": _vm.isDevelopment,
      "content": "dev",
      "color": "light-purple",
      "overlap": ""
    }
  }, [_c('MestoLogo', {
    staticClass: "d-none d-sm-flex",
    staticStyle: {
      "width": "96px",
      "height": "25px"
    }
  }), _c('MLogo', {
    staticClass: "d-flex d-sm-none"
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('MInput', {
    staticClass: "text-subtitle-1 font-weight-medium",
    attrs: {
      "clearable": _vm.$vuetify.breakpoint.width >= 400,
      "append-icon": "mdi-magnify",
      "placeholder": "Поиск участников",
      "hide-details": "",
      "dark": ""
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.search.apply(null, arguments);
      },
      "click:append": _vm.search,
      "click:clear": _vm.clearSearch
    },
    model: {
      value: _vm.searchQuery,
      callback: function ($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  })], 1), _c('v-col', {
    staticClass: "d-flex justify-end flex-grow-0",
    attrs: {
      "md": "3"
    }
  }, [_c('TheProfileMenu'), _c('v-app-bar-nav-icon', {
    staticClass: "hidden-md-and-up mx-n3",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.showSideMenu(true);
      }
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }